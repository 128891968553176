/** Features Slider Section */

import Swiper, { Pagination } from "swiper";

export default {
    init() {
        if (document.querySelector('.swiper-features-slider-big')) {
            Array.from(document.querySelectorAll('.swiper-features-slider-big'))
                .forEach(el => {
                    el.swiper = new Swiper(el, {
                        modules: [Pagination],
                        slidesPerView: 1.1,
                        spaceBetween: 20,
                        speed: 600,
                        grabCursor: true,
                        pagination: {
                            el: '.swiper-pagination',
                            type: 'bullets',
                            clickable: true
                        },
                        breakpoints: {
                            576: {
                                slidesPerView: 1.12,
                                spaceBetween: 100,
                            },
                            768: {
                                slidesPerView: 1.12,
                                spaceBetween: 100,
                            },
                            992: {
                                slidesPerView: 1.1,
                            },
                            1200: {}
                        }
                    });
                    this.resize(el)
                });

            // window.addEventListener('resize', (e) => {
            //     Array.from(document.querySelectorAll('.swiper-features-slider-big'))
            //         .forEach(el => {
            //             this.resize(el);
            //         });
            // })
        }
    },
    resize(el) {
        if (window.innerWidth <= 767 && el.querySelector('.section__content')) {
            let contentHeight = 0;
            const swiperContent = el.querySelectorAll('.section__content')
            Array.from(swiperContent)
                .forEach(contentEl => {
                    let height = contentEl.getBoundingClientRect().height;
                    if(height > contentHeight) contentHeight = height;
                })

            Array.from(swiperContent)
                .forEach(contentEl => {
                    contentEl.style.height = contentHeight + "px";
                })
        }
    }
}

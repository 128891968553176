/** Images Gallery Section */

import Swiper from "swiper";
import simpleParallax from 'simple-parallax-js';

export default {
    init() {
        if (document.querySelector('.swiper-images-gallery')) {
            Array.from(document.querySelectorAll('.swiper-images-gallery'))
                .forEach((el, index) => {
                    el.swiper = new Swiper(el, {
                        enabled: false,
                        autoplay: {
                            delay: 1,
                            disableOnInteraction: false
                        },
                        slidesPerView: 'auto',
                        spaceBetween: 20,
                        loop: true,
                        // allowTouchMove: false,
                        speed: 4000,
                        loopedSlides: 0,
                        slidesOffsetBefore: el.dataset.row !== "1" ? 688 : 0,
                    });

                    new simpleParallax(el, {
                        delay: 0.4,
                        orientation: index % 2 === 0 ? 'left' : 'right',
                        scale: 1.7,
                        overflow: true,
                    })
                });
        }
    }
}

/** Testimonials Slider */

import Swiper, { Autoplay } from "swiper";

export default {
    init() {
        if (document.querySelector('.swiper-testimonials')) {
            Array.from(document.querySelectorAll('.swiper-testimonials'))
                .forEach(el => {
                    el.swiper = new Swiper(el, {
                        modules: [Autoplay],
                        autoplay: {
                            delay: 10000,
                            disableOnInteraction: false
                        },
                        speed: 400,
                        slidesPerView: 1,
                        spaceBetween: 100,
                        centeredSlides: true,
                        loop: true,
                        grabCursor: true,
                        autoHeight: true,
                        breakpoints: {
                            965: {
                                slidesPerView: 'auto',
                                spaceBetween: 100,
                                autoHeight: false
                            }
                        }
                    });
                });
        }
    }
}

import Swiper, { Autoplay } from "swiper";

export default {
    init() {
        if (document.querySelector('.swiper-logos')) {
            Array.from(document.querySelectorAll('.swiper-logos'))
                .forEach(el => {
                    el.swiper = new Swiper(el, {
                        modules: [Autoplay],
                        allowTouchMove: false,
                        autoplay: {
                            delay: 1,
                        },
                        speed: 4000,
                        slidesPerView: 'auto',
                        loop: true,
                        spaceBetween: 0,
                    });
                });
        }
    }
}

import Accordion from "./accordion";

export default {
    init() {
        if (document.querySelector('.accordion')) {
            Array.from(document.querySelectorAll('.accordion'))
                .forEach(el => {
                    el.accordion = new Accordion(el);
                });
        }
    }
}
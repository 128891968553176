/**
 * Form: Jobs Filter
 */

import Choices from "choices.js";

class JobFilterFrom {

    constructor(element) {

        if (!(element instanceof HTMLElement)) {
            throw new TypeError('Invalid element');
        }

        this.form = element;

        if (this.form.querySelector('select')) {
            Array.from(this.form.querySelectorAll('select'))
                .forEach(select => {

                    select.addEventListener('change', this.handleSelectChange.bind(this.form));

                    select.choices = new Choices(select, {
                        placeholder: false,
                        itemSelectText: '',
                        allowHTML: false,
                        shouldSortItems: true,
                        searchEnabled: false
                    });
                });
        }
    }

    /**
     * On select field change, submit the form
     *
     * @param event
     */
    handleSelectChange(event) {
        event.preventDefault();
        this.submit();
    }

    initAutocomplete() {
        const searchWrapper = this.form.querySelector('.search-wrapper.autocomplete');

        if (!searchWrapper)
            return;

        let suggestions = searchWrapper.dataset.suggestions;
        suggestions = suggestions.split(',');
        suggestions = suggestions.sort();
        searchWrapper.removeAttribute('data-suggestions');

        searchWrapper.autocomplete = new Autocomplete(searchWrapper, suggestions);
    }
}

export default {
    init() {
        if (document.querySelector('form[data-action="filter-jobs"]')) {
            Array.from(document.querySelectorAll('form[data-action="filter-jobs"]'))
                .forEach(form => form.handler = new JobFilterFrom(form));
        }
    }
}

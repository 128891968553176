/* globals jQuery, wordpress_globals */

import "../sass/theme.scss";

import $ from 'jquery';
import svgxhr from 'webpack-svgstore-plugin/src/helpers/svgxhr';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import MicroModal from "micromodal";

import ix from './modules/ix';
import nav from './modules/nav';
import jobsFilterForm from './modules/forms/jobsFilterForm';
import homeSlider from './modules/homeSlider';
import teamCallToAction from './modules/teamCallToAction';
import featuresSliderSection from './modules/featuresSliderSection';
import featuresSliderBigSection from './modules/featuresSliderBigSection';
import imagesGallerySection from './modules/imagesGallerySection';
import logosSection from './modules/logosSection'
import postsSliderSection from './modules/postsSliderSection';
import ourLocationsSection from './modules/ourLocationsSection';
import sectorsSliderSection from './modules/sectorsSliderSection';
import statisticsSection from './modules/statisticsSection';
import teamSliderSection from './modules/teamSliderSection';
import testimonialsSliderSection from './modules/testimonialsSliderSection';
import EmailAlerts from "./modules/emailAlerts";
import genericAccordion from "./modules/genericAccordion";
import applicationTestimonialsSlider from "./modules/applicationTestimonialsSlider";

window.atkinsSearch = {
	init: function () {

		document.addEventListener('DOMContentLoaded', () => {
			MicroModal.init();
		});

		this.helpers.initSvg();

		// Allow user to interrupt auto-scrolling
		$(document).bind('scroll mousedown wheel keyup', function (e) {
			if (e.which || e.type === 'mousedown' || e.type === 'mousewheel') {
				$(document.scrollingElement).stop();
			}
		});

		// Components
		jobsFilterForm.init();
		homeSlider.init();
		teamCallToAction.init();

		// Sections
		featuresSliderSection.init();
		featuresSliderBigSection.init();
		imagesGallerySection.init();
		logosSection.init();
		ourLocationsSection.init();
		postsSliderSection.init();
		sectorsSliderSection.init();
		statisticsSection.init();
		teamSliderSection.init();
		testimonialsSliderSection.init();
		genericAccordion.init();
		applicationTestimonialsSlider.init();

		// Globals
		ix.init();
		nav.init();

		if(document.querySelector('.account-form') !== null){
			this.initEmailAlerts();
		}

		// Smooth scrolling for anchor links, jQuery used for cross browser support
		$(document).on('click', 'a[href^="#"]:not([href="#"]):not([href="#0"])', function (event) {
			event.preventDefault();

			let navElement = document.getElementById('nav-primary');
			let offset = navElement ? navElement.offsetHeight : 100;
			offset += 47;

			$('html, body').animate({
				scrollTop: $($.attr(this, 'href')).offset().top - offset
			}, 700);
		});

		// Smooth scroll on page load
		if (window.location.hash) {
			let hash = window.location.hash;

			let navElement = document.getElementById('nav-primary');
			let offset = navElement ? navElement.offsetHeight : 100;
			offset += 47;

			if ($(hash).length) {
				$('html, body').animate({
					scrollTop: $(hash).offset().top - offset
				}, 700);
			}
		}
	},
	initMaps: function () {
		if (document.readyState === 'complete') {
			// The page is fully loaded
			ourLocationsSection.initMap();
		} else {
			document.onreadystatechange = () => {
				if (document.readyState === 'complete') {
					// document ready
					ourLocationsSection.initMap();
				}
			};
		}
	},
	initEmailAlerts: function () {
		if(document.querySelector('.account-form') !== null){
			Array.from(document.querySelectorAll('.account-form')).forEach(el => {
				el.accountForm = new EmailAlerts(el);
			});
		}

		if(document.querySelector('.checkbox-toggle .toggle') !== null){
			Array.from(document.querySelectorAll('.checkbox-toggle .toggle')).forEach(el => {
				let checked = true;
				el.parentNode.parentNode.querySelectorAll('.checkbox input').forEach(input => {
					if(input.checked === false){
						checked = false;
					}
				});

				if(checked){
					el.textContent = 'Deselect All';
				}

				el.addEventListener('click', function(e){
					e.preventDefault();
				
					if(el.textContent === 'Deselect All'){
						this.parentNode.parentNode.querySelectorAll('.checkbox input').forEach(input => {
							input.checked = false;
						});
						el.textContent = 'Select All';
					} else {
						this.parentNode.parentNode.querySelectorAll('.checkbox input').forEach(input => {
							input.checked = true;
						});
						el.textContent = 'Deselect All';
					}
				});
			});
		}
	},
	helpers: {
		requireAll: function (r) {
			r.keys().forEach(r);
		},
		initSvg: function () {
			svgxhr({
				filename: './wp-content/themes/class/dist/sprite.svg', // URL to fetch
			});
		}

	}

	// Prefer creating new modules when appropriate, rather than adding functions here
	// If you need something to be globally accessible, you can assign an imported module to this namespace
	//  i.e. import Contact from './contact';
	//    window.namespace.Contact = Contact;
};

window.atkinsSearch.init();


if (typeof NodeList.prototype.forEach !== 'function') {
	NodeList.prototype.forEach = Array.prototype.forEach;
}

// SVG Sprite Loader
window.atkinsSearch.helpers.requireAll(require.context('../images/icons/', true, /\.svg$/));

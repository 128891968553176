import AOS from 'aos';
import { isCrawlerUserAgent } from 'is-web-crawler';
import SwiperCursor from './SwiperCursor';

export default {
    init() {
        if (isCrawlerUserAgent()) {
            let aosElements = document.querySelectorAll('[data-aos]');

            Array.from(aosElements)
                .forEach(el => {
                    if (el.dataset.aos) {
                        el.dataset.noAos = el.dataset.aos;
                        el.dataset.aos = "false";
                    }
                });
        } else {
            AOS.init({
                disable: 'mobile',
                duration: 700
            });
            this.initSwiperCursor();
        }
    },
    initSwiperCursor() {
        if (document.querySelector('.swiper-cursor')) {
            Array.from(document.querySelectorAll('.swiper-cursor'))
                .forEach(el => el.swiperCursor = new SwiperCursor(el));
        }
    }
}

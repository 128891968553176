/**
 * Our Locations Section
 */

import Swiper from "swiper";

export default {
    callback: 'window.atkinsSearch.initMaps',
    style: {},
    init() {
        if (wordpress_globals.google_maps_api_key && document.querySelector('[data-google-map]')) {

            // Get Map Style
            if (wordpress_globals.google_maps_style) {
                this.styles = JSON.parse(wordpress_globals.google_maps_style);
            }

            // Fetch the Google Maps js if needed
            this.enqueueGoogleMaps();
        }

        if (document.querySelector('.swiper-our-locations')) {
            Array.from(document.querySelectorAll('.swiper-our-locations'))
                .forEach(el => {

                    // let numberOfSlides = Array.from(el.querySelectorAll('.swiper-slide')).length;

                    el.swiper = new Swiper(el, {
                        slidesPerView: 1.12,
                        spaceBetween: 100,
                        speed: 600,
                        grabCursor: true
                    });
                });
        }
    },
    enqueueGoogleMaps() {
        let s = document.createElement('script'); s.type = 'text/javascript';
        s.src = `https://maps.googleapis.com/maps/api/js?key=${wordpress_globals.google_maps_api_key}&callback=${this.callback}`;
        s.async = 1;
        s.defer = 1;
        let c = document.getElementsByTagName('script')[0];
        c.parentNode.insertBefore(s, c);
    },
    initMap() {
        if (document.querySelector('.our_locations__map')) {
            Array.from(document.querySelectorAll('.our_locations__map'))
                .forEach(el => {
                    let location = new google.maps.LatLng(el.dataset.lat, el.dataset.lng);
                    let map = document.createElement('div');
                    let markerIcon = {
                        url: '/wp-content/themes/class/dist/images/marker@2x.png',
                        size: new google.maps.Size(176, 174),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(44, 87),
                        scaledSize: new google.maps.Size(88, 87)
                    }

                    el.googleMap = new google.maps.Map(map, {
                        zoom: 13,
                        center: location,
                        disableDefaultUI: true,
                        styles: this.styles,
                    });

                    el.googleMap.markers = [];
                    el.googleMap.markers.push(new google.maps.Marker({
                        position: location,
                        icon: markerIcon,
                        map: el.googleMap
                    }));

                    el.insertAdjacentElement('afterbegin', map);
                    el.dataset.googleMap = 'initialized';
                });
        }
    }
}

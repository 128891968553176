/** Sectors Slider Section */

import Swiper from "swiper";

export default {
    init() {
        if (document.querySelector('.swiper-sectors-slider')) {
            Array.from(document.querySelectorAll('.swiper-sectors-slider'))
                .forEach(el => {
                    el.swiper = new Swiper(el, {
                        slidesPerView: 1.2,
                        spaceBetween: 0,
                        grabCursor: true,
                        breakpoints: {
                            600: {
                                slidesPerView: 2.2,
                            },
                            768: {
                                slidesPerView: 3.2,
                            },
                            992: {
                                slidesPerView: 4.2,
                            },
                            1200: {
                                slidesPerView: 4,
                            }
                        }
                    });
                });
        }
    }
}

/**
 * Application Testimonials Slider
 */

import Swiper, { Autoplay, Pagination } from "swiper";

export default {
    init() {
        if (document.querySelector('.swiper-application-testimonials')) {
            Array.from(document.querySelectorAll('.swiper-application-testimonials'))
                .forEach(el => {
                    el.swiper = new Swiper(el, {
                        modules: [Autoplay, Pagination],
                        autoplay: {
                            delay: 10000
                        },
                        speed: 1000,
                        slidesPerView: 1,
                        spaceBetween: 20,
                        grabCursor: true,
                        pagination: {
                            el: '.swiper-pagination',
                            type: 'bullets',
                            clickable: true
                        }
                    });
                });
        }
    }
}

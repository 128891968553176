export default class EmailAlerts{
	constructor(element){
		this.form = element;

		if(!this.form instanceof HTMLFormElement){
			this.form = document.querySelector(this.form);
		}

		if(this.form.querySelector('button[type="submit"]') !== null){
			this.button = this.form.querySelector('button[type="submit"]');
		}

		this.form.addEventListener('submit', this.process.bind(this));
	}

	process(event){
		event.preventDefault();

		//console.log(this.form.dataset.action);

		if(typeof this.form.dataset.action === 'undefined'){
			return false;
		}

		let formData = new FormData(this.form),
		    formObj = {};

		formData.forEach((value, key) => {
			let regex = /(.*?)\[(.*?)\]/g,
				matches = regex.exec(key);

			if(key.indexOf('[]') !== -1){
				let san_key = key.replace('[]', '');

				if(typeof formObj[san_key] === 'undefined'){
					formObj[san_key] = [];
				}

				formObj[san_key].push(value);
			} else if(matches !== null){
				if(typeof formObj[matches[1]] === 'undefined'){
					formObj[matches[1]] = {};
				}

				formObj[matches[1]][matches[2]] = value;
			} else {
				formObj[key] = value
			}
		});

		//console.log(formObj);

		if(this.button !== null){
			this.button.disabled = true;
			this.button.classList.add('btn--loading');
		}

		return fetch(wordpress_globals.api_root + this.form.dataset.action, {
			method: 'POST',
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
				'X-WP-Nonce' : wordpress_globals.nonce
			},
			body: JSON.stringify(formObj)
		})
		.then(response => response.json())
		.then(data => {
			//console.log(data);
			let alert = document.createElement('div');

			alert.classList.add('alert');

			if(typeof data.data.status !== 'undefined' && (data.data.status >= 400 && data.data.status < 500)){
				alert.classList.add('alert--error');
			}

			alert.innerText = data.message || 'Updated';
			alert.timeout = setTimeout(() => { alert.remove() }, 7000);

			this.form.insertAdjacentElement('afterbegin', alert);

			if(data.data.status === 200 && (typeof this.form.dataset.redirect !== 'undefined' && this.form.dataset.redirect.length)){
				setTimeout(() => {
					window.location = this.form.dataset.redirect;
				}, 1000);
			}
		})
		.then(() => {
			if(this.button !== null){
				this.button.disabled = false
				this.button.classList.remove('btn--loading');
			}
		});
	}
}

/** Home Slider */

import Swiper, { Autoplay, EffectFade } from "swiper";

export default {
    init() {
        if (document.querySelector('.swiper-home')) {
            Array.from(document.querySelectorAll('.swiper-home'))
                .forEach(el => {
                    el.swiper = new Swiper(el, {
                        modules: [Autoplay, EffectFade],
                        autoplay: {
                            delay: 3000
                        },
                        speed: 2000,
                        slidesPerView: 1,
                        spaceBetween: -1,
                        loop: true,
                        effect: 'fade',
                        fadeEffect: {
                            crossFade: true
                        },
                    });
                });
        }
    }
}

/** Nav */

export default {
    init() {

        this.menu = document.getElementById('nav-side');
        this.navBar = document.getElementById('nav-primary');

        const openMenuButtons = document.querySelectorAll('[data-action="menu-open"]');
        const closeMenuButtons = document.querySelectorAll('[data-action="menu-close"]');

        if (this.menu) {
            openMenuButtons.forEach(el => {
                el.addEventListener('click', this.toggleMenu.bind(this));
            });
            closeMenuButtons.forEach(el => {
                el.addEventListener('click', this.toggleMenu.bind(this));
            });
        }

        if (this.navBar) {
            const navHeight = this.navBar.getBoundingClientRect().height - 50;
            document.addEventListener('scroll', () => {
                this.navBar.classList.toggle('scrolling', window.scrollY > 0);
            });
        }
    },
    toggleMenu(e) {
        if (e) { e.preventDefault(); }

        this.menu.classList.toggle('open');
    }
}

/** Posts Slider Section */

import Swiper, { FreeMode } from "swiper";

export default {
    init() {
        if (document.querySelector('.swiper-posts-slider')) {
            Array.from(document.querySelectorAll('.swiper-posts-slider'))
                .forEach(el => {
                    el.swiper = new Swiper(el, {
                        modules: [FreeMode],
                        freeMode: true,
                        slidesPerView: 'auto',
                        spaceBetween: 20,
                        grabCursor: true
                    });
                });
        }
    }
}

/** Statistics Section */

import { CountUp } from "countup.js";

export default {
    init() {
        document.addEventListener('aos:in:count-up', this.countUp.bind(this));
    },
    countUp(e) {
        let el = e.detail;
        if (el.dataset.aosId === "count-up") {
            let stat = el.querySelector('[data-figure]');

            if (stat.dataset.figure === "")
                return;

            let options = {
                duration: 2.6,
                easingFn: function (t, b, c, d) {
                    let ts = (t /= d) * t,
                        tc = ts * t;
                    return b + c * (tc + -3 * ts + 3 * t);
                }
            }

            if (typeof stat.dataset.figure !== 'undefined') {
                let number = stat.dataset.figure.replace(/[^\d\.\-]/g, "");

                options.decimalPlaces = (number.indexOf('.') === -1) ? 0 : number.split('.')[1].length;

                if (typeof stat.dataset.prefix !== 'undefined') {
                    options.prefix = stat.dataset.prefix;
                }

                if (typeof stat.dataset.suffix !== 'undefined') {
                    options.suffix = stat.dataset.suffix;
                }

                let demo = new CountUp(stat, parseFloat(number), options);

                if (!demo.error) {
                    demo.start();
                } else {
                    console.warn(demo.error);
                }
            } else {
                console.log('countUp num undefined');
            }
        }
    }
}

/**
 * Cursor Follow
 */

class SwiperCursor {

    constructor(element) {

        if (!(element instanceof HTMLElement)) {
            throw new TypeError('Invalid element');
        }

        this.element = element;
        this.tracking = false;

        this.hoverElement = document.createElement('div');
        this.hoverElement.classList.add('hover__cursor');
        const labelClassList = `hover__label ${this.element.dataset.hover ? 'hover__label--no-arrows' : ''}`;
        this.hoverElement.innerHTML = `<div class="${labelClassList}"><span>${this.element.dataset.hover ?? ''}</span></div>`;

        this.element.appendChild(this.hoverElement);

        this.element.addEventListener('mouseenter', this.handleMouseEnter.bind(this));
        this.element.addEventListener('mouseleave', this.handleMouseLeave.bind(this));
        this.element.addEventListener('mousemove', this.handleMouseMove.bind(this));
    }

    updateTextPosition(e, parentElement, hoverElement) {
        let parentRect = parentElement.getBoundingClientRect();
        let x = e.clientX - parentRect.left;
        let y = e.clientY - parentRect.top;

        requestAnimationFrame(() => hoverElement.style.transform = `translate(${x}px, ${y}px)`);
    }

    handleMouseEnter(e) {
        if (!this.tracking) {
            let parentRect = this.element.getBoundingClientRect();
            let x = e.clientX - parentRect.left;
            let y = e.clientY - parentRect.top;

            this.hoverElement.style.transform = `translate(${x}px, ${y}px)`;
            this.hoverElement.classList.toggle('active', true);
        }
        this.tracking = true;
    }

    handleMouseLeave(e) {
        // this.hoverElement.classList.toggle('active', false);
        // this.tracking = false;
    }

    handleMouseMove(e) {
        this.updateTextPosition(e, this.element, this.hoverElement);
    }

}

export default SwiperCursor;

/** Team Slider Section */

import Swiper, { Pagination } from "swiper";

export default {
    init() {
        if (document.querySelector('.swiper-team-slider')) {
            Array.from(document.querySelectorAll('.swiper-team-slider'))
                .forEach(el => {
                    el.swiper = new Swiper(el, {
                        modules: [Pagination],
                        slidesPerView: 1.05,
                        spaceBetween: 38,
                        grabCursor: true,
                        pagination: {
                            el: '.swiper-pagination',
                            type: 'bullets',
                            clickable: true
                        },
                        breakpoints: {
                            768: {
                                slidesPerView: 'auto',
                                spaceBetween: 0,
                            }
                        }
                    });
                });
        }
    }
}

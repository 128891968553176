/** Team Call To Action */

import Cookies from 'cookies-js';

export default {
    init() {

        this.cookieName = 'atkinssearch_team_call-to-action-state';
        this.ctaWrapper = document.querySelector('.call-to-action-wrapper');
        this.footer = document.getElementById('footer');

        if (!this.ctaWrapper) {
            return;
        }

        this.teamCta = this.ctaWrapper.querySelector('.team-cta');

        this.initConsultantDetailsToggle();
        this.initAnchorCTA();

        // Use cookie to set and store the current state of the Call-To-Action
        (Cookies.get(this.cookieName) === 'open') ? this.open() : this.close();
    },
    open() {
        this.teamCta.classList.toggle('open', true);
        this.ctaWrapper.classList.toggle('open', true);
        Cookies.set(this.cookieName, 'open');
    },
    close() {
        this.teamCta.classList.toggle('open', false);
        this.ctaWrapper.classList.toggle('open', false);
        Cookies.set(this.cookieName, 'closed');
    },
    // Toggle for consultant contact details on mobile
    initConsultantDetailsToggle() {
        const openCta = this.ctaWrapper.querySelector('.open-cta');
        const closeCta = this.ctaWrapper.querySelector('.close-cta');

        openCta.addEventListener('click', this.open.bind(this));

        closeCta.addEventListener('click', this.close.bind(this));
    },

    // Fix the Call-To-Action to the top of the site footer once user scrolls past it
    initAnchorCTA() {
        const updateAnchorState = () => {
            let isAnchored = window.innerHeight < this.footer.getBoundingClientRect().top;
            if (this.ctaWrapper.classList.contains('is-anchored')) {
                isAnchored = window.innerHeight < (this.footer.getBoundingClientRect().top + this.ctaWrapper.offsetHeight);
            }
            this.ctaWrapper.classList.toggle('is-anchored', isAnchored);
        };

        window.addEventListener('scroll', updateAnchorState);
        updateAnchorState();
    }
}

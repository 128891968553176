/** Features Slider Section */

import Swiper from "swiper";

export default {
    init() {
        if (document.querySelector('.swiper-features-slider')) {
            Array.from(document.querySelectorAll('.swiper-features-slider'))
                .forEach(el => {
                    el.swiper = new Swiper(el, {
                        slidesPerView: 2.2,
                        spaceBetween: 45,
                        grabCursor: true,
                        breakpoints: {
                            576: {
                                slidesPerView: 2.5,
                            },
                            768: {
                                slidesPerView: 3.5,
                                spaceBetween: 50,
                            },
                            992: {
                                slidesPerView: 4.5,
                                spaceBetween: 50,
                            },
                            1200: {
                                slidesPerView: 5,
                                spaceBetween: 50,
                            }
                        }
                    });
                });
        }
    }
}
